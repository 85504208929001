import React, {useEffect} from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import {useForm} from "react-hook-form";
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';

const MemberSurvey = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onSubmit = data => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (!validateCaptcha(user_captcha_value) === true) {
            alert('Captcha Does Not Match');
            return
        }

        let formdata = new FormData();
        formdata.append("your-name", data.name);
        formdata.append("account-number", data.accountNumber);
        formdata.append("your-phone", data.phoneNumber);
        formdata.append("csrKnowledgeable", data.csrKnowledgeable);
        formdata.append("csrCourteous", data.csrCourteous);
        formdata.append("csrResponsive", data.csrResponsive);
        formdata.append("csrAssistanceSatisfied", data.csrAssistanceSatisfied);
        formdata.append("firstContactKnowledgeable", data.firstContactKnowledgeable);
        formdata.append("representativeCourteous", data.representativeCourteous);
        formdata.append("representativeAssistanceSatisfied", data.representativeAssistanceSatisfied);
        formdata.append("technicianContacted", data.technicianContacted);
        formdata.append("technicianKnowledgeable", data.technicianKnowledgeable);
        formdata.append("technicianCourteous", data.technicianCourteous);
        formdata.append("technicianPrompt", data.technicianPrompt);
        formdata.append("technicianExplainedClearly", data.technicianExplainedClearly);
        formdata.append("technicianInformationProvided", data.technicianInformationProvided);
        formdata.append("technicianSatisfied", data.technicianSatisfied);
        formdata.append("serviceInstalled", data.serviceInstalled);
        formdata.append("linePersonnelProfessional", data.linePersonnelProfessional);
        formdata.append("serviceInstallationSatisfied", data.serviceInstallationSatisfied);
        formdata.append("cooperativeSatisfied", data.cooperativeSatisfied);

        const requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(`${process.env.GATSBY_API_URL}wp-json/contact-form-7/v1/contact-forms/851/feedback`, requestOptions)
            .then(response => response.json())
            .then(() => {
                alert('Form submitted successfully.')
                reset();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Layout>
            <Seo title="Member Survey" description="Member Survey Form"/>
            <div className="w-full px-8">
                <h1 className="item-title text-center">Member Survey</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="overflow-hidden sm:rounded-md">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <h2 className="font-extrabold">TRANSFER OF SERVICE</h2>
                                <p className="underline italic">Billing Dept.</p>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        When I initially contacted the Cooperative to request service the Customer
                                        Service Representative I spoke with was knowledgeable and professional.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="csr-knowledgeable-yes" type="radio" value="yes"
                                                   {...register("csrKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csr-knowledgeable-yes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="csr-knowledgeable-no" type="radio" value="no"
                                                   {...register("csrKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csr-knowledgeable-no"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.csrKnowledgeable?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Customer Service Representative was courteous and helpful.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="csrCourteousYes" name="csrCourteous" type="radio" value="yes"
                                                   {...register("csrCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrCourteousYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="csrCourteousNo" name="csrCourteous" type="radio" value="no"
                                                   {...register("csrCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrCourteousNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.csrCourteous?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Customer Service Representative was responsive.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="csrResponsiveYes" name="csrResponsive" type="radio" value="yes"
                                                   {...register("csrResponsive", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrResponsiveYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="csrResponsiveNo" name="csrResponsive" type="radio" value="no"
                                                   {...register("csrResponsive", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrResponsiveNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.csrResponsive?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        Overall I was satisfied with the assistance provided by the CSR.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="csrAssistanceSatisfiedYes" name="csrAssistanceSatisfied"
                                                   type="radio" value="yes"
                                                   {...register("csrAssistanceSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrAssistanceSatisfiedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="csrAssistanceSatisfiedNo" name="csrAssistanceSatisfied"
                                                   type="radio" value="no"
                                                   {...register("csrAssistanceSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="csrAssistanceSatisfiedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.csrAssistanceSatisfied?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <h2 className="font-extrabold">NEW SERVICE REQUEST</h2>
                                <p className="underline italic">Eng. & Operations Secretary</p>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        When I initially contacted the Cooperative to request new service the
                                        Representative I spoke with was knowledgeable and professional.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="firstContactKnowledgeableYes" name="firstContactKnowledgeable"
                                                   type="radio" value="yes"
                                                   {...register("firstContactKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="firstContactKnowledgeableYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="firstContactKnowledgeableNo" name="firstContactKnowledgeable"
                                                   type="radio" value="no"
                                                   {...register("firstContactKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="firstContactKnowledgeableNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.firstContactKnowledgeable?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Representative was courteous and helpful.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="representativeCourteousYes" name="representativeCourteous"
                                                   type="radio" value="yes"
                                                   {...register("representativeCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="representativeCourteousYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="representativeCourteousNo" name="representativeCourteous"
                                                   type="radio" value="no"
                                                   {...register("representativeCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="representativeCourteousNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.representativeCourteous?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        Overall I was satisfied with the assistance provided by the Representative.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="representativeAssistanceSatisfiedYes"
                                                   name="representativeAssistanceSatisfied" type="radio" value="yes"
                                                   {...register("representativeAssistanceSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="representativeAssistanceSatisfiedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="representativeAssistanceSatisfiedNo"
                                                   name="representativeAssistanceSatisfied" type="radio" value="no"
                                                   {...register("representativeAssistanceSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="representativeAssistanceSatisfiedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.representativeAssistanceSatisfied?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <p className="underline italic">Engineering Dept.</p>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        From my initial contact with the Cooperative, the Engineering Technician
                                        assigned to my job contacted me in a timely fashion.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianContactedYes" name="technicianContacted" type="radio"
                                                   value="yes"
                                                   {...register("technicianContacted", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianContactedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianContactedNo" name="technicianContacted" type="radio"
                                                   value="no"
                                                   {...register("technicianContacted", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianContactedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianContacted?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Engineering Technician was knowledgeable and professional.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianKnowledgeableYes" name="technicianKnowledgeable"
                                                   type="radio" value="yes"
                                                   {...register("technicianKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianKnowledgeableYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianKnowledgeableNo" name="technicianKnowledgeable"
                                                   type="radio" value="no"
                                                   {...register("technicianKnowledgeable", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianKnowledgeableNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianKnowledgeable?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Engineering Technician was courteous and helpful.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianCourteousYes" name="technicianCourteous" type="radio"
                                                   value="yes"
                                                   {...register("technicianCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianCourteousYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianCourteousNo" name="technicianCourteous" type="radio"
                                                   value="no"
                                                   {...register("technicianCourteous", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianCourteousNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianCourteous?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Engineering Technician was easy to get in contact with and prompt to return
                                        calls.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianPromptYes" name="technicianPrompt" type="radio"
                                                   value="yes"
                                                   {...register("technicianPrompt", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianPromptYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianPromptNo" name="technicianPrompt" type="radio"
                                                   value="no"
                                                   {...register("technicianPrompt", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianPromptNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianPrompt?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Engineering Technician explained everything clearly.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianExplainedClearlyYes" name="technicianExplainedClearly"
                                                   type="radio" value="yes"
                                                   {...register("technicianExplainedClearly", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianExplainedClearlyYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianExplainedClearlyNo" name="technicianExplainedClearly"
                                                   type="radio" value="no"
                                                   {...register("technicianExplainedClearly", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianExplainedClearlyNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianExplainedClearly?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The Engineering Technician provided all the information/documentation needed in
                                        a timely manner.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianInformationProvidedYes"
                                                   name="technicianInformationProvided" type="radio" value="yes"
                                                   {...register("technicianInformationProvided", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianInformationProvidedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianInformationProvidedNo"
                                                   name="technicianInformationProvided" type="radio" value="no"
                                                   {...register("technicianInformationProvided", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianInformationProvidedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianInformationProvided?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        Overall I was satisfied with the service provided by the Engineering Technician.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="technicianSatisfiedYes" name="technicianSatisfied" type="radio"
                                                   value="yes"
                                                   {...register("technicianSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianSatisfiedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="technicianSatisfiedNo" name="technicianSatisfied" type="radio"
                                                   value="no"
                                                   {...register("technicianSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="technicianSatisfiedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.technicianSatisfied?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <p className="underline italic">Operations</p>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        My service was installed/connected in a timely manner.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="serviceInstalledYes" name="serviceInstalled" type="radio"
                                                   value="yes"
                                                   {...register("serviceInstalled", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="serviceInstalledYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="serviceInstalledNo" name="serviceInstalled" type="radio"
                                                   value="no"
                                                   {...register("serviceInstalled", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="serviceInstalledNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.serviceInstalled?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        The line personnel were professional and courteous.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="linePersonnelProfessionalYes" name="linePersonnelProfessional"
                                                   type="radio" value="yes"
                                                   {...register("linePersonnelProfessional", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="linePersonnelProfessionalYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="linePersonnelProfessionalNo" name="linePersonnelProfessional"
                                                   type="radio" value="no"
                                                   {...register("linePersonnelProfessional", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="linePersonnelProfessionalNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.linePersonnelProfessional?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        I was satisfied with the service installation.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="serviceInstallationSatisfiedYes"
                                                   name="serviceInstallationSatisfied" type="radio" value="yes"
                                                   {...register("serviceInstallationSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="serviceInstallationSatisfiedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="serviceInstallationSatisfiedNo"
                                                   name="serviceInstallationSatisfied" type="radio" value="no"
                                                   {...register("serviceInstallationSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="serviceInstallationSatisfiedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.serviceInstallationSatisfied?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>
                            <div className="col-span-6">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        Overall I am satisfied with the service provided by my Cooperative.
                                    </legend>
                                    <div className="mt-2 space-y-1 flex flex-wrap">
                                        <div className="flex items-center mr-4 gap-x-3">
                                            <input id="cooperativeSatisfiedYes" name="cooperativeSatisfied" type="radio"
                                                   value="yes"
                                                   {...register("cooperativeSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="cooperativeSatisfiedYes"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input id="cooperativeSatisfiedNo" name="cooperativeSatisfied" type="radio"
                                                   value="no"
                                                   {...register("cooperativeSatisfied", {required: true})}
                                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                                            <label htmlFor="cooperativeSatisfiedNo"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {errors.cooperativeSatisfied?.type === 'required' &&
                                        <span className="error text-red">Please fill out this field.</span>}
                                </fieldset>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    {...register("name", {required: true})}
                                    name="name"
                                    id="name"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.name?.type === 'required' &&
                                    <span className="error text-red">Name is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    {...register("phoneNumber", {required: true})}
                                    id="phoneNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.phoneNumber?.type === 'required' &&
                                    <span className="error text-red">Phone Number is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    Account Number
                                </label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                    {...register("accountNumber")}
                                    id="accountNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="workPhoneNumber" className="block text-sm font-medium text-gray-700">
                                    Enter text in field below for validation.
                                </label>
                                <LoadCanvasTemplate/>
                                <input
                                    type="text"
                                    name="captcha"
                                    {...register("captcha", {required: true})}
                                    id="user_captcha_input"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.captcha?.type === 'required' &&
                                    <span className="error text-red">Captcha is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default MemberSurvey
